/**
 * @param path DBデータそのままであれば `ui/contents/100/article/1234.jpg` のような形で渡ってくる
 */
export function createArticleImagePath(path: string) {
  return '/' + path;
}

/**
 * @param path DBデータそのままであれば `ui/contents/100/article/1234.jpg` のような形で渡ってくる
 * @return `ui/contents/100/article/1234_t.jpg` のように _t をつけた形
 */
export function createArticleThumbImagePath(path: string) {
  const [base, extension] = path.split('.');
  return `/${base}_t.${extension}`;
}
